import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import ReactHtmlParser from 'html-react-parser';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Arrow } from '@/shared/icons';
import { BlogPost } from '@/domain/entities/post';
import { translateCategoryById } from '@/helpers/categories';

import customStyles from './carousel-custom-styles.module.css';
import {
  CarouselCard,
  CarouselCardContent,
  CarouselCardHeader,
  StyledCarousel,
  StyledLink,
  Wrapper,
} from './styles';

type CarouselProps = {
  posts: Array<BlogPost>;
};

export default function Carousel({ posts }: CarouselProps) {
  return (
    <StyledCarousel>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination, Autoplay]}
        pagination={{
          clickable: true,
          bulletClass: customStyles.bulletClass,
          bulletActiveClass: customStyles.bulletActiveClass,
        }}
        autoplay
        loop
      >
        {posts.map((post, index) => (
          <SwiperSlide key={index}>
            <CarouselCard>
              <CarouselCardHeader image={post.media} />
              <Wrapper>
                <CarouselCardContent>
                  <div className="category-container">
                    <span className="category">{translateCategoryById(post.category).name}</span>
                    <span className="reading-time">{post.readingTime} min de leitura</span>
                  </div>
                  <h2>{post.title}</h2>
                  <div>{ReactHtmlParser(post.description)}</div>
                  <StyledLink href={`/${post.slug}`}>
                    Leia mais
                    <Arrow />
                  </StyledLink>
                </CarouselCardContent>
              </Wrapper>
            </CarouselCard>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledCarousel>
  );
}
