import * as yup from 'yup';
import Image from 'next/image';
import Link from 'next/link';
import ReactHtmlParser from 'html-react-parser';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BlogPost } from '@/domain/entities/post';
import Title from '@/components/Title';
import { categories } from '@/helpers/categories';
import { BiSearchAlt2 } from 'react-icons/bi';

import { List, Container, TitleContainer, Card, HighlightedCard, SearchForm } from './styles';

type RecentPostsProps = {
  posts: Array<BlogPost>;
};

type SearchProps = {
  search: string;
};

export default function MostSeenPosts({ posts }: RecentPostsProps) {
  const highlightedPost = posts[0];
  const { push } = useRouter();

  const schema = yup.object().shape({
    search: yup.string().required(''),
  });

  const { register, handleSubmit } = useForm<SearchProps>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSearch = (data: SearchProps) => {
    push(`/buscar?s=${data.search}`);
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Mais Vistos</Title>
        <SearchForm onSubmit={handleSubmit(onSearch)}>
          <input type="search" required placeholder="Pesquisar" {...register('search')} />
          <button>
            <BiSearchAlt2 />
          </button>
        </SearchForm>
      </TitleContainer>

      <List>
        {highlightedPost ? (
          <HighlightedCard>
            <Link href={`/${highlightedPost.slug}`}>
              <Image src={highlightedPost.media} alt="imagem" width={364} height={85} />
              <div className="content">
                <span className="category">{categories[highlightedPost.category].name}</span>
                <h3 className="title">{highlightedPost.title}</h3>
                <div className="description">{ReactHtmlParser(highlightedPost.description)}</div>
                <p>{highlightedPost.readingTime} min de leitura</p>
              </div>
            </Link>
          </HighlightedCard>
        ) : null}

        {posts.slice(1).map((post, index) => (
          <Card key={index}>
            <Link href={`/${post.slug}`}>
              <div className="content">
                <span className="category">{categories[post.category].name}</span>
                <h3 className="title">{post.title}</h3>
                <p>{post.readingTime} min de leitura</p>
              </div>
            </Link>
          </Card>
        ))}
      </List>
    </Container>
  );
}
