import Link from 'next/link';
import styled from 'styled-components';

type CarouselCardHeaderProps = {
  image: string;
};

export const StyledCarousel = styled.section`
  margin: 0 auto;
  background: var(--highlight-blue);
  position: relative;

  .swiper-pagination {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
  }

  @media (min-width: 1100px) {
    .swiper-horizontal {
      position: initial !important;
    }

    .swiper-slide {
      position: initial !important;
    }

    .swiper-wrapper {
      height: 675px;
    }

    .swiper-pagination {
      bottom: 20px;
    }
  }
`;

export const CarouselCard = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 1100px) {
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 85%;
`;

export const CarouselCardHeader = styled.div<CarouselCardHeaderProps>`
  background: url(${({ image }) => image && image}) no-repeat center;
  background-size: cover;
  height: 115px;
  width: 100%;
  margin-bottom: -4px;

  @media (min-width: 768px) {
    height: 230px;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 50%;
    right: 0;
    z-index: 1;
  }
`;
export const CarouselCardContent = styled.div`
  width: 85%;
  margin: 0 auto;
  padding: 1.75rem 0 2.5rem;
  color: var(--text-color-secondary);
  display: flex;
  flex-direction: column;

  .category-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .category {
    font-size: 1.325rem;
    font-weight: 600;
    color: var(--highlight-blue);
    background: var(--text-color-secondary);
    display: inline-block;
    padding: 0.25rem 1.75rem;
    width: max-content;
    margin-bottom: 0.8rem;
  }

  .reading-time {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 400;
  }

  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.625rem;
    margin: 1.5rem 0;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 768px) {
    .category-container {
      flex-direction: row;
      align-items: center;
      gap: 1.2rem;
    }

    .category {
      margin-bottom: 0;
    }
  }
  @media (min-width: 1100px) {
    max-width: 453px;
    margin: 0 0;
    padding: 5.5rem 0 3rem;

    .category-container {
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 1.95rem;
    }

    p {
      font-size: 1.475rem;
      margin-bottom: 3rem;
    }
  }

  @media (min-width: 1100px) {
    h2 {
      font-size: 2.15rem;
      margin-bottom: 2.15rem;
    }
    p {
      font-size: 1.6rem;
    }
  }
`;

export const StyledLink = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  color: var(--text-color-secondary);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  width: 13rem;
  padding: 1rem;
  margin: auto 0 1rem;
  border-radius: 2px;
  border: 2px solid #fff;
  transition: border, background 0.3s ease-in-out;

  &:hover {
    background: #0a1e32;
    border-color: #0a1e32;
  }

  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }

  @media (min-width: 1100px) {
    width: 15rem;
    font-size: 1.325rem;
    margin-bottom: 0;
  }
`;
