type IconProps = {
  color: string;
};

export function Sun({ color }: IconProps) {
  return (
    <svg width="29" height="29" fill="none" viewBox="0 0 29 29">
      <path
        fill={color}
        d="M14.102 23.397c-5.128 0-9.294-4.167-9.294-9.295s4.166-9.294 9.294-9.294 9.295 4.166 9.295 9.294-4.167 9.295-9.295 9.295zm0-16.666c-4.064 0-7.371 3.307-7.371 7.371s3.307 7.372 7.371 7.372 7.372-3.308 7.372-7.372-3.308-7.371-7.372-7.371zM14.102 28.153c-.705 0-1.282-.526-1.282-1.231v-.103c0-.705.577-1.282 1.282-1.282.705 0 1.282.577 1.282 1.282 0 .706-.577 1.334-1.282 1.334zm9.154-3.615c-.334 0-.654-.129-.91-.372l-.167-.167c-.5-.5-.5-1.308 0-1.808s1.307-.5 1.807 0l.167.167a1.277 1.277 0 01-.897 2.18zm-18.307 0c-.334 0-.654-.129-.91-.372-.5-.5-.5-1.308 0-1.808l.166-.167c.5-.5 1.308-.5 1.808 0s.5 1.308 0 1.808l-.167.167a1.283 1.283 0 01-.897.372zm21.973-9.154h-.103a1.286 1.286 0 01-1.282-1.282c0-.705.577-1.282 1.282-1.282.706 0 1.334.577 1.334 1.282 0 .705-.526 1.282-1.231 1.282zm-25.537 0h-.103A1.286 1.286 0 010 14.102c0-.705.577-1.282 1.282-1.282.705 0 1.333.577 1.333 1.282 0 .705-.525 1.282-1.23 1.282zm21.704-8.987c-.333 0-.654-.128-.91-.372-.5-.5-.5-1.307 0-1.807l.166-.167c.5-.5 1.308-.5 1.808 0s.5 1.308 0 1.808l-.167.166a1.261 1.261 0 01-.897.372zm-17.974 0c-.333 0-.654-.128-.91-.372l-.167-.18c-.5-.5-.5-1.307 0-1.807s1.308-.5 1.808 0l.167.167c.5.5.5 1.308 0 1.808a1.244 1.244 0 01-.898.384zm8.987-3.782c-.705 0-1.282-.525-1.282-1.23v-.103C12.82.577 13.397 0 14.102 0c.705 0 1.282.577 1.282 1.282 0 .705-.577 1.333-1.282 1.333z"
      />
    </svg>
  );
}

export function Moon({ color }: IconProps) {
  return (
    <svg width="23" height="25" fill="none" viewBox="0 0 23 25">
      <path
        fill={color}
        d="M12.23 24.153c-.186 0-.372 0-.557-.011C5.563 23.869.457 18.948.031 12.947-.339 7.797 2.64 2.986 7.44.967 8.803.4 9.524.837 9.829 1.153c.305.305.731 1.014.164 2.313A8.943 8.943 0 009.25 7.12c.022 4.833 4.037 8.936 8.936 9.132a9.432 9.432 0 002.084-.142c1.44-.262 2.04.317 2.27.688.228.37.49 1.167-.383 2.346-2.313 3.164-5.99 5.008-9.929 5.008zM1.656 12.827c.37 5.194 4.8 9.449 10.081 9.678a10.618 10.618 0 009.09-4.32c.163-.23.24-.393.272-.48a1.718 1.718 0 00-.546.032c-.796.142-1.625.196-2.444.164-5.76-.23-10.474-5.063-10.507-10.758 0-1.506.295-2.957.895-4.321.109-.24.13-.404.142-.491-.098 0-.273.022-.557.142-4.157 1.745-6.732 5.913-6.426 10.354z"
      />
    </svg>
  );
}

export function Clock() {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30">
      <rect width="30" height="30" fill="#F7C82A" rx="8" />
      <path
        fill="#112EF7"
        d="M15.262 23.063C10.816 23.063 7.2 19.447 7.2 15c0-4.447 3.615-8.063 8.063-8.063 4.447 0 8.062 3.615 8.062 8.063s-3.615 8.063-8.063 8.063zm0-15A6.946 6.946 0 008.325 15a6.946 6.946 0 006.938 6.938A6.946 6.946 0 0022.2 15a6.946 6.946 0 00-6.938-6.938z"
      />
      <path
        fill="#112EF7"
        d="M18.045 17.948a.502.502 0 01-.285-.083l-2.325-1.387c-.578-.345-1.005-1.103-1.005-1.77v-3.075c0-.308.255-.563.562-.563.308 0 .563.255.563.563v3.075c0 .27.225.667.457.802l2.325 1.388c.27.157.353.502.195.772a.578.578 0 01-.487.278z"
      />
    </svg>
  );
}

export function Computer() {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30">
      <rect width="30" height="30" fill="#F7C82A" rx="8" />
      <path
        fill="#112EF7"
        d="M19.138 19.443h-8.276C7.9 19.443 7 18.543 7 15.58v-4.718C7 7.9 7.9 7 10.862 7h8.268c2.962 0 3.863.9 3.863 3.862v4.71c.007 2.97-.893 3.87-3.855 3.87zM10.862 8.116c-2.336 0-2.746.41-2.746 2.746v4.71c0 2.338.41 2.747 2.746 2.747h8.268c2.337 0 2.746-.41 2.746-2.746v-4.71c0-2.337-.409-2.747-2.746-2.747h-8.268z"
      />
      <path
        fill="#112EF7"
        d="M15 23a.562.562 0 01-.558-.558v-3.557c0-.305.253-.559.558-.559.305 0 .558.254.558.559v3.557A.562.562 0 0115 23zM22.442 16.302H7.558A.562.562 0 017 15.744c0-.305.253-.558.558-.558h14.884c.305 0 .558.253.558.558a.562.562 0 01-.558.558z"
      />
      <path
        fill="#112EF7"
        d="M18.349 23H11.65a.562.562 0 01-.558-.558c0-.305.253-.558.558-.558h6.698c.305 0 .558.253.558.558a.562.562 0 01-.558.558z"
      />
    </svg>
  );
}

export function Calendar() {
  return (
    <svg width="30" height="30" fill="none" viewBox="0 0 30 30">
      <rect width="30" height="30" fill="#F7C82A" rx="8" />
      <path
        fill="#112EF7"
        d="M12.113 10.975a.567.567 0 01-.563-.563v-2.25c0-.307.255-.562.563-.562.307 0 .562.255.562.562v2.25a.567.567 0 01-.562.563zM18.113 10.975a.567.567 0 01-.563-.563v-2.25c0-.307.255-.562.563-.562.307 0 .562.255.562.562v2.25a.567.567 0 01-.562.563zM21.488 14.042H8.738a.567.567 0 01-.563-.562c0-.308.255-.563.563-.563h12.75c.307 0 .562.255.562.563a.567.567 0 01-.562.562z"
      />
      <path
        fill="#112EF7"
        d="M18.112 23.725h-6c-2.737 0-4.312-1.575-4.312-4.313v-6.375c0-2.737 1.575-4.312 4.312-4.312h6c2.738 0 4.313 1.575 4.313 4.312v6.375c0 2.738-1.575 4.313-4.313 4.313zm-6-13.875c-2.145 0-3.187 1.042-3.187 3.187v6.375c0 2.146 1.042 3.188 3.187 3.188h6c2.145 0 3.188-1.043 3.188-3.188v-6.375c0-2.145-1.043-3.187-3.188-3.187h-6z"
      />
      <path
        fill="#112EF7"
        d="M12.488 17.537a.747.747 0 01-.285-.06.866.866 0 01-.248-.157.866.866 0 01-.158-.248.747.747 0 01-.06-.285c0-.195.083-.39.218-.532a.866.866 0 01.248-.158.717.717 0 01.57 0c.045.015.09.038.135.068l.112.09c.03.037.068.075.09.112.03.045.053.09.068.135.022.045.037.09.045.135.007.053.015.098.015.15 0 .195-.083.39-.218.533a.788.788 0 01-.532.217zM15.113 17.537a.789.789 0 01-.533-.217l-.09-.113a.568.568 0 01-.068-.135.477.477 0 01-.045-.135c-.007-.052-.014-.097-.014-.15 0-.097.022-.195.06-.285a.866.866 0 01.157-.247.752.752 0 01.818-.158c.097.038.172.09.247.158a.789.789 0 01.218.532c0 .053-.008.098-.015.15a.477.477 0 01-.045.135.568.568 0 01-.068.135l-.09.113a.774.774 0 01-.247.157.747.747 0 01-.285.06zM12.488 20.162a.747.747 0 01-.285-.06.866.866 0 01-.248-.157.774.774 0 01-.158-.248.747.747 0 01-.06-.285c0-.195.083-.39.218-.532a.866.866 0 01.248-.158.75.75 0 01.817.158c.03.037.068.075.09.112.03.045.053.09.068.135.022.045.037.09.045.143a.919.919 0 01.015.142c0 .195-.083.39-.218.533a.788.788 0 01-.532.217z"
      />
    </svg>
  );
}

export function Arrow() {
  return (
    <svg width="56" height="16" fill="none" viewBox="0 0 56 16">
      <path
        fill="#fff"
        d="M54.888 8.422a1 1 0 000-1.414L48.524.644a1 1 0 10-1.415 1.414l5.657 5.657-5.657 5.657a1 1 0 001.415 1.414l6.364-6.364zM0 8.715h54.18v-2H0v2z"
      />
    </svg>
  );
}
