import { BlogPost } from '@/domain/entities/post';
import { PostGateway } from '@/domain/gateways/post.gateway';

export class ListPostsUseCase {
  constructor(private postGate: PostGateway) {}

  async execute(params?: { [key: string]: string | number }): Promise<BlogPost[]> {
    return this.postGate.findAll(params);
  }
}
