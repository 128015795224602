import { BlogPost } from '@/domain/entities/post';
import { PostGateway } from '@/domain/gateways/post.gateway';

//Dependency Inversion
export class GetPostUseCase {
  constructor(private postGate: PostGateway) {}

  execute(slug: string): Promise<BlogPost> {
    return this.postGate.findBySlug(slug);
  }
}
