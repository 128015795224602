import 'reflect-metadata';
import { Container } from 'inversify';
import { GetPostUseCase } from '@/application/post/get-post.use-case';
import { ListPostsUseCase } from '@/application/post/list-posts.use-case';
import { PostHttpGateway } from './gateways/post-http.gateway';
import { http } from './http';
import { AuthorHttpGateway } from './gateways/author-http.gateway';
import { GetAuthorUseCase } from '@/application/author/get-author.use-case';
import { ListMostSeenPostsUseCase } from '@/application/post/list-most-seen-posts';

export const Registry = {
  AxiosAdapter: Symbol.for('AxiosAdapter'),

  PostGateway: Symbol.for('PostGateway'),
  AuthorGateway: Symbol.for('AuthorGateway'),

  ListPostsUseCase: Symbol.for('ListPostsUseCase'),
  GetPostUseCase: Symbol.for('GetPostUseCase'),
  ListMostSeenPostsUseCase: Symbol.for('ListMostSeenPostsUseCase'),

  GetAuthorUseCase: Symbol.for('GetAuthorUseCase'),
};

export const container = new Container();

//########## HTTP
container.bind(Registry.AxiosAdapter).toConstantValue(http);

//########## GATEWAYS
container.bind(Registry.PostGateway).toDynamicValue(context => {
  return new PostHttpGateway(context.container.get(Registry.AxiosAdapter));
});

container.bind(Registry.AuthorGateway).toDynamicValue(context => {
  return new AuthorHttpGateway(context.container.get(Registry.AxiosAdapter));
});

//########## USE CASES
container.bind(Registry.ListPostsUseCase).toDynamicValue(context => {
  return new ListPostsUseCase(context.container.get(Registry.PostGateway));
});

container.bind(Registry.GetPostUseCase).toDynamicValue(context => {
  return new GetPostUseCase(context.container.get(Registry.PostGateway));
});

container.bind(Registry.ListMostSeenPostsUseCase).toDynamicValue(context => {
  return new ListMostSeenPostsUseCase(context.container.get(Registry.PostGateway));
});

container.bind(Registry.GetAuthorUseCase).toDynamicValue(context => {
  return new GetAuthorUseCase(context.container.get(Registry.AuthorGateway));
});
