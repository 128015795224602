export type CategoryId =
  | 1379
  | 1362
  | 1382
  | 1363
  | 1364
  | 1378
  | 1383
  | 1366
  | 1381
  | 1384
  | 1367
  | 1385
  | 1;

export type BlogPost = {
  id: number;
  slug: string;
  category: CategoryId;
  title: string;
  description: string;
  content?: string | null;
  date: string;
  media: string;
  authorId: number;
  readingTime: number;
};

export class Post {
  constructor(public props: BlogPost) {}

  get id() {
    return this.props.id;
  }

  get slug() {
    return this.props.slug;
  }

  get title() {
    return this.props.title;
  }

  get description() {
    return this.props.description;
  }

  get content() {
    return this.props.content;
  }

  get category() {
    return this.props.category;
  }

  get date() {
    return this.props.date;
  }

  get media() {
    return this.props.media;
  }

  get authorId() {
    return this.props.authorId;
  }

  get readingTime() {
    return this.props.readingTime;
  }

  toJSON() {
    return {
      id: this.id,
      slug: this.slug,
      title: this.title,
      description: this.description,
      content: this.content ?? null,
      category: this.category,
      date: this.date,
      media: this.media,
      authorId: this.authorId,
      readingTime: this.readingTime,
    };
  }
}
