import { AxiosInstance } from 'axios';
import { Author } from '@/domain/entities/author';
import { AuthorGateway } from '@/domain/gateways/author.gateway';

export class AuthorHttpGateway implements AuthorGateway {
  constructor(private http: AxiosInstance) {}

  async findById(id: number): Promise<Author> {
    return this.http.get<Author>(`/wp/v2/users/${id}`).then((res: any) => {
      return new Author({
        id: res.data.id,
        name: res.data.name,
        avatarUrl: res.data.avatar_urls['96'],
      });
    });
  }
}
