import { Author } from '@/domain/entities/author';
import { AuthorGateway } from '@/domain/gateways/author.gateway';

//Dependency Inversion
export class GetAuthorUseCase {
  constructor(private authorGate: AuthorGateway) {}

  execute(id: number): Promise<Author> {
    return this.authorGate.findById(id);
  }
}
