import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 2.5rem;
  margin: 0 auto;
  max-width: 1170px;
  width: 85%;

  @media (min-width: 1024px) {
    padding: 4.25rem 0 2.5rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    h2 {
      margin-bottom: 0;
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: 4.5rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    height: 32.625rem;
    gap: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
  }
`;

export const Card = styled.li`
  background: #f5f5f5;
  border-radius: 20px;
  height: 9.375rem;
  transition: all 0.3s ease-in-out;

  .content {
    overflow: hidden;
    max-width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
  }

  a {
    height: 100%;
    width: 100%;
  }

  &:hover {
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .category {
    color: var(--highlight-blue);
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .title {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    .title {
      font-size: 1.375rem;
    }
  }

  @media (min-width: 1100px) {
    .content {
      max-width: 600px;
    }
  }
`;

export const HighlightedCard = styled.li`
  width: fit-content;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;

  img {
    display: block;
    height: 30%;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  a {
    background: #f5f5f5;
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    box-shadow: 4px 4px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--highlight-blue);
    color: var(--text-color-secondary);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: none;
    min-height: 14rem;
  }

  .category {
    color: #b9c3d7;
    display: block;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .title {
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }

  .description {
    p {
      font-size: 1rem;
    }
  }

  @media (min-width: 370px) {
    .title {
      font-size: 1.725rem;
    }
  }

  @media (min-width: 1024px) {
    max-width: none;

    .content {
      gap: 1rem;
      flex-grow: 1;
    }

    a {
      height: 32.625rem;
      width: 23.313rem;
    }

    img {
      height: 25%;
    }
  }

  @media (min-width: 1400px) {
    .content {
      flex-grow: initial;
      height: 50%;
    }

    a {
      height: 32.625rem;
      width: 38.313rem;
      margin-bottom: 0;
    }

    img {
      height: 50%;
    }
  }
`;

export const SearchForm = styled.form`
  background: #f5f5f5;
  border-radius: 10px;
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    background: #f5f5f5;
    color: #b9b9b9;
    padding-left: 1rem;
    border-radius: 10px;
    font-size: 1.25rem;
    height: 100%;
    width: 75%;
  }
  button {
    background: 0 0;
    height: 2.375rem;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    svg {
      font-size: 1.275rem;
    }

    &:hover {
      svg {
        path {
          fill: var(--highlight-blue);
        }
      }
    }
  }

  @media (min-width: 768px) {
    width: 14.5rem;
    height: 2.5rem;

    input {
      font-size: 1rem;
      padding-left: 1.2rem;
    }

    button {
      padding: 0.8rem;
    }
  }
`;
