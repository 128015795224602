import styled from 'styled-components';

export const Container = styled.section`
  padding-top: 2.5rem;
  width: 85%;
  max-width: var(--max-width);
  border-bottom: 1px solid #dddddd;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 4.25rem 0 2.5rem;
    max-width: 1170px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 2.5rem 3.6rem;
  margin: 0 auto;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const PaginationContainer = styled.div`
  margin: 0 auto;
  padding: 4.5rem 0 2.5rem;
  width: fit-content;
`;
