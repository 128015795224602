import { readingTime } from '@/helpers/post-reading-time';
import { AxiosInstance } from 'axios';
import { Post } from '../../domain/entities/post';
import { PostGateway } from '../../domain/gateways/post.gateway';

export class PostHttpGateway implements PostGateway {
  constructor(private http: AxiosInstance) {}

  async findAll(params?: { [key: string]: string | number }): Promise<Post[]> {
    return this.http
      .get<Post[]>('/wp/v2/posts', {
        params: params,
      })
      .then((res: any) =>
        res.data.map((data: any) =>
          new Post({
            id: data.id,
            slug: data.slug,
            title: data.title.rendered,
            description: data.excerpt.rendered,
            category: data.categories[0],
            content: null,
            date: data.date,
            media: data.jetpack_featured_media_url,
            authorId: data.author,
            readingTime: readingTime(data.content.rendered),
          }).toJSON()
        )
      )
      .catch(err => err);
  }

  async findBySlug(slug: string): Promise<Post> {
    const params = {
      slug: slug,
    };
    return this.http
      .get<Post>(`/wp/v2/posts`, {
        params: params,
      })
      .then((res: any) => {
        const data = res.data.shift();
        return new Post({
          id: data.id,
          slug: data.slug,
          title: data.title.rendered,
          description: data.excerpt.rendered,
          content: data.content.rendered,
          category: data.categories[0],
          date: data.date,
          media: data.jetpack_featured_media_url,
          authorId: data.author,
          readingTime: readingTime(data.content.rendered),
        }).toJSON();
      })
      .catch(err => err);
  }

  async listMostSeenPosts(): Promise<Post[]> {
    return this.http
      .get<Post[]>(
        '/wp_query/args?meta_key=post_views_count&orderby=meta_value_num&posts_per_page=4&ignore_sticky_posts=true'
      )
      .then((res: any) =>
        res.data.map((data: any) =>
          new Post({
            id: data.id,
            slug: data.slug,
            title: data.title.rendered,
            description: data.excerpt.rendered,
            category: data.categories[0],
            content: null,
            date: data.date,
            media: data.jetpack_featured_media_url,
            authorId: data.author,
            readingTime: readingTime(data.content.rendered),
          }).toJSON()
        )
      )
      .catch(err => err);
  }
}
