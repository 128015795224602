import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';

const Container = styled.div`
  .MuiPagination-ul {
    max-width: 100%;
  }
  .MuiPaginationItem-root {
    font-weight: 700 !important;

    &:hover {
      background: #d2d2d2 !important;
    }
  }
  .Mui-selected {
    background: var(--highlight-blue) !important;

    &:hover {
      background: var(--highlight-blue) !important;
    }
  }
`;

type CustomPaginationProps = {
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number;
  count?: number;
};

export default function CustomPagination({ handleChange, page, count = 1 }: CustomPaginationProps) {
  const isMobile = useMediaQuery('(max-width: 540px)');
  return (
    <Container>
      <Stack spacing={1}>
        <Pagination
          count={count}
          size={isMobile ? 'small' : 'medium'}
          page={page}
          color="primary"
          onChange={handleChange}
        />
      </Stack>
    </Container>
  );
}
