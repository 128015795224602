import { useRef, useState } from 'react';
import { BlogPost } from '@/domain/entities/post';
import { ListPostsUseCase } from '@/application/post/list-posts.use-case';
import { container, Registry } from '@/infra/container-registry';
import CustomPagination from '@/components/CustomPagination';
import PostCard from '@/components/PostCard';
import Title from '@/components/Title';

import { List, Container, PaginationContainer } from './styles';

type LatestContentsProps = {
  posts: Array<BlogPost>;
};

export default function LatestContents({ posts }: LatestContentsProps) {
  const [postsList, setPostsList] = useState(posts);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  function scrollToRef() {
    if (ref.current) window.scrollTo({ top: ref.current.offsetTop - 180 });
  }

  const fetchPosts = async (page: number) => {
    const listPostsUseCase = container.get<ListPostsUseCase>(Registry.ListPostsUseCase);
    const posts = await listPostsUseCase.execute({ per_page: 12, page: page });
    return posts;
  };

  const handleChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    setIsLoading(true);
    setPage(value);
    const posts = await fetchPosts(value);
    setPostsList(posts);
    setIsLoading(false);
    scrollToRef();
  };

  return (
    <Container>
      <div ref={ref}>
        <Title>ÚLTIMOS CONTEÚDOS</Title>
      </div>

      <List>
        {postsList.map((post, index) => (
          <PostCard key={index} post={post} showCategory isLoading={isLoading} />
        ))}
      </List>

      <PaginationContainer>
        <CustomPagination handleChange={handleChange} page={page} count={20} />
      </PaginationContainer>
    </Container>
  );
}
