import Head from 'next/head';
import { GetStaticProps } from 'next';
import Header from '@/components/Header';
import { container, Registry } from '@/infra/container-registry';
import { ListPostsUseCase } from '@/application/post/list-posts.use-case';
import { BlogPost } from '@/domain/entities/post';
import LatestContents from '@/components/LatestContents';
import Carousel from '@/components/Carousel';
import { Title } from '@/styles/pages/home';
import CategoriesSection from '@/components/CategoriesSection';
import { ListMostSeenPostsUseCase } from '@/application/post/list-most-seen-posts';
import MostSeenPosts from '@/components/MostSeenPosts';

type HomeProps = {
  featuredPosts: Array<BlogPost>;
  initialPosts: Array<BlogPost>;
  mostSeenPosts: Array<BlogPost>;
};

export default function Home({ featuredPosts, initialPosts, mostSeenPosts }: HomeProps) {
  return (
    <>
      <Head>
        <title>Blog - Kenzie Academy Brasil</title>
        <link rel="canonical" href="https://kenzie.com.br/blog" />
      </Head>
      <Header />
      <main>
        <Title>BLOG DA KENZIE ACADEMY</Title>
        <Carousel posts={featuredPosts} />
        <MostSeenPosts posts={mostSeenPosts} />
        <LatestContents posts={initialPosts} />
        <CategoriesSection />
      </main>
    </>
  );
}

export const getStaticProps: GetStaticProps = async context => {
  const listPostsUseCase = container.get<ListPostsUseCase>(Registry.ListPostsUseCase);
  const listMostSeenPostsUseCase = container.get<ListMostSeenPostsUseCase>(
    Registry.ListMostSeenPostsUseCase
  );

  const featuredPosts = await listPostsUseCase.execute({ tags: 1380 });
  const initialPosts = await listPostsUseCase.execute({ per_page: 12 });
  const mostSeenPosts = await listMostSeenPostsUseCase.execute();

  return {
    props: {
      featuredPosts: featuredPosts,
      initialPosts: initialPosts,
      mostSeenPosts: mostSeenPosts,
    },
  };
};
