export type BlogAuthor = {
  id: string;
  name: string;
  avatarUrl: string;
};

export class Author {
  constructor(public props: BlogAuthor) {}

  get id() {
    return this.props.id;
  }

  get name() {
    return this.props.name;
  }

  get avatarUrl() {
    return this.props.avatarUrl;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      avatarUrl: this.avatarUrl,
    };
  }
}
