import { BlogPost } from '@/domain/entities/post';
import { PostGateway } from '@/domain/gateways/post.gateway';

export class ListMostSeenPostsUseCase {
  constructor(private postGate: PostGateway) {}

  async execute(): Promise<BlogPost[]> {
    return this.postGate.listMostSeenPosts();
  }
}
